<template>
    <div class="footer_div" v-if="switchovers && switchoverJaneTraditionals">
        <a href="/">
            <div :class="{ footer_one: true, opacity: index }">
                <center>
                    <div class="footer">
                        <img
                            :class="{
                                footer_img: port == 'phone' || !port,
                                footer_img_pc: port == 'pc',
                            }"
                            src="../assets/footer/tabbar_icon_1.png"
                        />
                    </div>
                </center>
                <div class="footers">{{ index_s }}</div>
            </div>
        </a>

        <a href="/content">
            <div :class="{ footer_one: true, opacity: content }">
                <center>
                    <div class="footer">
                        <img
                            :class="{
                                footer_img: port == 'phone' || !port,
                                footer_img_pc: port == 'pc',
                            }"
                            src="../assets/footer/tabbar_icon_2.png"
                        />
                    </div>
                </center>
                <div class="footers">{{ content_s }}</div>
            </div>
        </a>

        <a href="/task">
            <div :class="{ footer_one: true, opacity: task }">
                <center>
                    <div class="footer">
                        <img
                            :class="{
                                footer_img: port == 'phone' || !port,
                                footer_img_pc: port == 'pc',
                            }"
                            src="../assets/footer/tabbar_icon_3.png"
                        />
                    </div>
                </center>
                <div class="footers">{{ task_s }}</div>
            </div>
        </a>

        <a href="/my">
            <div :class="{ footer_one: true, opacity: my }">
                <center>
                    <div class="footer">
                        <img
                            :class="{
                                footer_img: port == 'phone' || !port,
                                footer_img_pc: port == 'pc',
                            }"
                            src="../assets/footer/tabbar_icon_4.png"
                        />
                    </div>
                </center>
                <div class="footers">{{ my_s }}</div>
            </div>
        </a>
    </div>
</template>

<style>
.footer_div {
    position: sticky;
    z-index: 2;
    left: 0;
    bottom: 0;
    background: #cba77e;
    /* background:#788bf9; */
    overflow: hidden;
    box-shadow: 1px 1px 6px #888;
    font-size: 12px;
    /* height:10vh; */
    /* padding-top: 1vh; */
    width: 100%;
}
.footer_one {
    float: left;
    width: 25%;
    height: 100%;
    padding-top: 1rem;
}
.footer {
    width: 30%;
    height: 45%;
}
.footers {
    width: 100%;
    height: 10%;
    color: #6c5c31;
}
.footer_img {
    width: 100%;
    height: 100%;
    filter: invert(30%) sepia(49%) saturate(438%) hue-rotate(6deg)
        brightness(97%) contrast(88%) !important;
}
.footer_img_pc {
    width: 40%;
    height: 100%;
    filter: invert(30%) sepia(49%) saturate(438%) hue-rotate(6deg)
        brightness(97%) contrast(88%) !important;
}
.opacity {
    opacity: 0.45;
}
</style>
<script>
export default {
    name: "Footer",
    data() {
        return {
            index: true,
            content: true,
            task: true,
            my: true,
            // 切換簡繁體
            switchovers: true,
            // 切換使用位置
            switchoverJaneTraditionals: true,
            index_s: "",
            task_s: "",
            content_s: "",
            my_s: "",
            port: "",
        };
    },
    created() {
        let lang_id = this.$store.state.lang_id;
        this.port = this.$store.state.port;
        if (lang_id == 1) {
            this.index_s = this.$zh_ft("index");
            this.task_s = this.$zh_ft("task");
            this.content_s = this.$zh_ft("content");
            this.my_s = this.$zh_ft("my");
        } else {
            this.index_s = this.$zh_jt("index");
            this.task_s = this.$zh_jt("task");
            this.content_s = this.$zh_jt("content");
            this.my_s = this.$zh_jt("my");
        }

        let url = this.$route.fullPath;
        let split = url.split("/");
        let contents = url.split("?");
        if (url == "/") {
            this.index = false;
            // (split[0] == '' && split[1] == 'search_result' && split[2].split('index?content=')[1]) 首頁搜索内容 時會跳到另外一個頁面這個頁面隸屬於内容頁下面 所以做了下面的判斷
        } else if (
            url == "/content" ||
            (split[0] == "" &&
                split[1] == "search_result" &&
                split[2].split("index?content=").length == 2) ||
            (contents[0] == "/content" &&
                contents[1].split("=")[0] == "sort_id" &&
                contents[1].split("=")[1] > 0)
        ) {
            this.content = false;
        } else if (url == "/task") {
            this.task = false;
        } else if (url == "/my") {
            this.my = false;
        }
    },
    computed: {
        switchover() {
            return this.$store.state.switchover;
        },
        switchoverJaneTraditional() {
            return this.$store.state.switchoverJaneTraditional;
        },
    },
    watch: {
        switchover(val) {
            this.switchovers = !val;
        },
        switchoverJaneTraditional(val) {
            this.switchoverJaneTraditionals = !val;
        },
    },
};
</script>
